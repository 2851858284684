@import "./animations.scss";

* {
  box-sizing: border-box;
  margin: 0;
  a {
    text-decoration: none;
    color: black;
  }
  a:hover {
    font-style: italic;
  }
}

body {
  width: 100%;
  min-height: 300vh;

  font-family: "Poppins", sans-serif;
  font-size: large;
  background-image: url(../images/web.png);
  background-position: cover;
  background-size: cover;
  background-repeat: no-repeat;
}

p {
  font-size: 18px;
}

.left-aligned {
  text-align: left;
}

.right-aligned {
  text-align: right;
}

.timeline {
  font-size: 14px;
  text-decoration: underline;
}

.linkbar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: flex-end;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  margin-top: 0px;
  padding: 0;
  gap: 10px;
  i {
    font-size: x-large;
    margin: 15px;
    color: black;
  }
}

.welcome-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100vh;
  padding: 0 20px;
  margin-bottom: 100px;
  margin-top: -130px;
  p {
    font-size: 20px;
    strong {
      font-size: 40px;
      font-family: "Comfortaa", cursive;
    }
    em {
      font-size: larger;
      font-style: normal;
    }
  }
  .quicklinks {
    text-align: left;
    ul {
      list-style: none;
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: left;

      gap: 17px;
      li {
        font-size: larger;
        -webkit-box-shadow: 10px 10px 30px -26px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 30px -26px rgba(0, 0, 0, 0.75);
        box-shadow: 10px 10px 30px -26px rgba(0, 0, 0, 0.75);
      }
      li:hover {
        text-decoration: underline;
      }
    }
  }
}

.title {
  font-size: 40px;
  text-align: center;
  font-family: "Comfortaa", cursive;
  margin-top: 100px;
}

.projects-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.project {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 100px 0;
  * {
    padding: 10px;
  }
  p {
    width: 60vh;
  }
  img {
    height: 50%;
    width: 20vw;
    margin: 20px;
    outline: 2px solid white;
    border-radius: 10px;
    -webkit-box-shadow: 10px 10px 30px -26px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 10px 30px -26px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 10px 30px -26px rgba(0, 0, 0, 0.75);
  }
  img:hover {
    outline: 2px solid rgb(255, 157, 174);
    border-radius: 10px;
  }
  h3:hover {
    font-style: italic;
  }
}

.readme:hover {
  font-style: italic;
}

.tech-container {
  margin: 90px 0 90px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
  height: 100vh;
  div {
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //border: 1px solid green;
    i {
      font-size: xx-large;
    }
  }
  div:hover {
    background-color: rgba(248, 175, 175, 0.603);
    border-radius: 15px;
  }
}

.interests {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 110px;
  h3 {
    font-size: x-large;
  }
}

.about-me {
  text-align: center;
  margin: 100px 10px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h3 {
    margin-bottom: 20px;
  }
}

.contact {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  p {
    font-size: large;

    strong {
      font-size: larger;
      font-family: "Comfortaa", cursive;
    }
    strong:hover {
      font-style: italic;
    }
    button {
      background: none;
      border: none;
      font-size: large;
      i {
        margin-right: 7px;
      }
    }
    button:hover {
      text-decoration: underline;
    }
  }
}

@media only screen and (max-width: 600px) {
  body {
    background-image: url(../images/mobile.png);
  }
  .welcome-container,
  .about-me,
  .contact,
  .interests,
  .projects-container {
    padding: 0 20px;
  }

  .linkbar {
    padding: 10px 20px;
  }

  .welcome-container {
    p {
      font-size: 20px;
    }
    .quicklinks {
      text-align: center;
      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 5px;
        gap: 5px;
        li {
          text-decoration: underline;
        }
      }
    }
  }
  .project {
    flex-direction: column;
    p {
      width: 100%;
      order: 1;
    }
    img {
      width: 50vw;
      order: 2;
    }
  }

  .interests {
    div {
      width: 70%;
    }
    :nth-child(3) {
      align-self: flex-end;
    }
  }
}

@media only screen and (min-width: 768px) {
  .welcome-container,
  .about-me,
  .contact,
  .interests {
    padding: 0 20vh;
  }
  .linkbar {
    padding: 20px 40px;
  }

  .tech-container {
    width: 1000px;
    margin: 150px auto;
    div {
      width: 200px;
      height: 200px;
    }
  }
}
